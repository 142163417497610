import styled, { keyframes, css } from "styled-components";
import { useThemeTokens, Typography } from "@alphasights/alphadesign-components";
import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";

const SpeakerTypography = styled(Typography).attrs({ variant: "body-em" })(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base02};
    align-items: baseline;
  `;
});

const FragmentTypography = styled(EmphasisTypography).attrs({ component: "span" })(
  ({ shouldFlash }: { shouldFlash: boolean }) => {
    const { font, spacing, color, shape } = useThemeTokens();
    const flashAnimation = keyframes`
    0% { background-color: ${color.background.surface.page.default}; }
    50% { background-color: ${color.background.highlightBase}; }
    100% { background-color: ${color.background.surface.page.default}; }
  `;
    return css`
      margin-left: ${spacing.inner.base10};
      padding-left: ${spacing.inner.base02};
      line-height: ${font.lineHeight["03"]};
      animation: ${shouldFlash ? flashAnimation : "none"} 1s ease-in-out;
      border-radius: ${shape.border.radius.medium};
    `;
  }
);

const FragmentWrapper = styled.div(() => {
  const { color, spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base};
    color: ${color.text.strong._};
  `;
});

const SpeakerWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base06};
    align-items: center;
  `;
});

const TranscriptWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    width: 644px;
    max-width: 644px;
    gap: ${spacing.inner.base04};
    margin: ${spacing.inner.base06} auto;
    padding: ${spacing.inner.base06};
  `;
});

export { SpeakerTypography, FragmentTypography, FragmentWrapper, SpeakerWrapper, TranscriptWrapper };
