import { useRef, useState, useMemo } from "react";
import { useQuery } from "query-utils";
import * as S from "./Topbar.styled";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";
import {
  Button,
  Icon,
  IconButton,
  ListOption,
  Pill,
  Popover,
  Typography,
  useAlphaToast,
} from "@alphasights/alphadesign-components";
import { Add, ChevronDown, Copy, Mail, Send } from "@alphasights/alphadesign-icons";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { InteractionsService } from "services/interactionsService";
import { DynamicPills } from "@alphasights/client-portal-shared";
import { ANGLE_TYPES } from "models/AngleQuestions";
import { useNavigate } from "router-utils";
import { MessageType } from "types";
import { NewMessageOrigin } from "pages/InteractionPage/enums";
import { ENABLE_PORTAL_MESSAGES, useProjectBadgeContext } from "providers/BadgeProvider";
import { Project } from "@alphasights/portal-api-client";
import { useAnglesTypesFromProject } from "../helpers";
import { compact } from "lodash";

export const Topbar = ({ loading, onSendNewMessage }: { loading: boolean; onSendNewMessage: any }) => {
  const {
    selectedAngleTypeId,
    selectedAngleQuestionThemes,
    copyAngleThemeQuestions,
    addAngleQuestionTheme,
    readOnly,
    getAngleThemeQuestionsText,
  } = useAngleQuestionsContext();
  const { project } = useCurrentProjectContext();
  const { toast } = useAlphaToast();
  const navigate = useNavigate();
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState<Element>();
  const open = Boolean(anchorEl);
  const { hasProjectBadge } = useProjectBadgeContext();

  const messengerEnabled = hasProjectBadge(ENABLE_PORTAL_MESSAGES);

  const angleTypes = useAnglesTypesFromProject();
  const angleType = angleTypes.find((angle) => angle.id === selectedAngleTypeId);

  const handleCopyAngleThemeQuestions = () => {
    copyAngleThemeQuestions(selectedAngleQuestionThemes.map((theme) => theme.id)).then(() =>
      toast.success({ message: "Questions copied to clipboard." })
    );
  };

  const handleClosePopover = () => {
    setAnchorEl(undefined);
  };

  const handleSendQuestionSetClick = ({ currentTarget }: { currentTarget: Element }) => {
    if (!anchorEl) {
      setAnchorEl(currentTarget);
    } else {
      setAnchorEl(undefined);
    }
  };

  const handleSendCallGuide = () => {
    const questionsText =
      "During the call we will be discussing topics surrounding these questions:\n\n" +
      getAngleThemeQuestionsText(selectedAngleQuestionThemes.map((theme) => theme.id));
    onSendNewMessage([], MessageType.CallGuide, NewMessageOrigin.Standard, questionsText);
    navigate(`/${project?.token}/experts/messages-view`);
  };

  const popover = (
    <Popover ref={ref} anchorEl={anchorEl} open={open} onClose={handleClosePopover} placement="bottom-end">
      <ListOption
        type="text"
        leftIcon={
          <Icon color="secondary">
            <Mail />
          </Icon>
        }
        disabled={readOnly}
        onChange={handleSendCallGuide}
      >
        Send as Call Guide
      </ListOption>
    </Popover>
  );

  return (
    <S.Wrapper data-testid="angle-questions-topbar">
      {loading || !angleType || !project ? (
        <>
          <S.TopbarTitleLoading />
          <S.TopbarActionsLoading />
        </>
      ) : (
        <S.TopbarContent>
          <S.TitleWrapper>
            <Typography variant="body-large-em">{angleType.name}</Typography>
            <Companies key={angleType.id} project={project} angleType={angleType} />
          </S.TitleWrapper>
          <S.ButtonsWrapper>
            <IconButton variant="outline" size="small" onClick={handleCopyAngleThemeQuestions} testId="copy-button">
              <Copy />
            </IconButton>
            {messengerEnabled && (
              <Button
                variant="outline"
                size="small"
                startIcon={<Send />}
                endIcon={<ChevronDown />}
                onClick={handleSendQuestionSetClick}
              >
                Send Question Set
              </Button>
            )}
            <Button
              variant="secondary"
              size="small"
              startIcon={<Add />}
              onClick={addAngleQuestionTheme}
              disabled={readOnly}
              dataAttributes={{ "data-testid": "add-topic-button" }}
            >
              Add Topic
            </Button>
            {popover}
          </S.ButtonsWrapper>
        </S.TopbarContent>
      )}
    </S.Wrapper>
  );
};

const Companies = ({ project, angleType }: { project: Project; angleType: AngleType }) => {
  const {
    state: { interactions },
  } = useProjectInteractionsContext();
  const ref = useRef<HTMLDivElement>(null);

  const interactionIdsFromAngleType = useMemo(
    () =>
      (interactions as Interaction[])
        .filter((interaction) => {
          const angleTypeIds = interaction.angles.flatMap((angle) => compact([angle.type?.id, angle.parent?.type?.id]));
          return angleTypeIds.includes(angleType.id);
        })
        .map((interaction: Interaction) => interaction.id),
    [angleType.id, interactions]
  );
  const isTargetCompanyAngleType = angleType.name === ANGLE_TYPES.TargetCompany;

  const { data: companies = [] } = useQuery(
    ["getUniqueRelevantCompanies", angleType.id],
    () =>
      isTargetCompanyAngleType
        ? Promise.resolve(project.investmentTargetCompanies)
        : InteractionsService.getUniqueRelevantCompanies(project.token, interactionIdsFromAngleType),
    {
      enabled: interactionIdsFromAngleType.length > 0 || isTargetCompanyAngleType,
      refetchOnMount: false,
    }
  );

  if (companies.length === 0) {
    return null;
  }

  return (
    <DynamicPills containerRef={ref} showFirst={true} showTooltip={true} customBuffer={10}>
      {companies.map((company) => (
        <Pill key={company.id} size="small" variant="outline" isInteractive={false} img={company.logo}>
          {company.name}
        </Pill>
      ))}
    </DynamicPills>
  );
};
