import React, { FC, ForwardedRef, forwardRef, useCallback, useEffect, useMemo, useRef } from "react";
import { uniq, map } from "lodash";
import { Avatar } from "@alphasights/alphadesign-components";
import { Expert } from "@alphasights/alphadesign-icons";

import useQueryParams from "hooks/useQueryParams";
import { TranscriptFragment } from "services/thirdPartyDocumentsService";
import { UrlParam } from "views/DeliverablesView/constants";
import { ThirdPartyDocumentExpert } from "types";
import { getSpeakerName } from "./utils";
import { AVATAR_COLORS } from "./constants";
import useScrollToFragment from "./useScrollToFragment";

import * as S from "./TranscriptViewer.styled";

export const DataTestIds = {
  TRANSCRIPT_VIEWER: "transcript-viewer",
};

type TranscriptViewerProps = {
  onError: () => void;
  ref: ForwardedRef<HTMLDivElement>;

  fragments?: TranscriptFragment[];
  experts?: ThirdPartyDocumentExpert[];
};

const TranscriptViewer: FC<TranscriptViewerProps> = forwardRef(
  ({ fragments, onError, experts = [] }, ref: ForwardedRef<HTMLDivElement>) => {
    const queryParams = useQueryParams();
    const part = queryParams.get(UrlParam.part);

    const fragmentRefs = useRef<HTMLDivElement[]>([]);
    const { scrolledToFragmentIndex, isScrolling } = useScrollToFragment(fragmentRefs.current);

    const orderedUniqueSpeakers = useMemo(() => uniq(map(fragments, "sanitized_source")), [fragments]);

    const _getSpeakerName = useCallback(
      (speaker: string) => {
        const uniqueSpeakerIndex = orderedUniqueSpeakers.indexOf(speaker);
        const expert = uniqueSpeakerIndex > 0 ? experts[uniqueSpeakerIndex - 1] : {};
        return getSpeakerName(speaker, expert?.companyName, expert?.position);
      },
      [orderedUniqueSpeakers, experts]
    );

    useEffect(() => {
      if (part) {
        const partInt = parseInt(part);
        if (fragments && (partInt >= fragments.length || partInt < 0)) {
          queryParams.delete(UrlParam.part, true);
        }
      }
    }, [fragments, part, queryParams]);

    const render = () => {
      if (fragments?.length) {
        try {
          return (
            <S.TranscriptWrapper data-testid={DataTestIds.TRANSCRIPT_VIEWER} ref={ref}>
              {fragments.map((fragment) => {
                const { sanitized_source: speaker, content, index } = fragment;
                const speakerName = _getSpeakerName(speaker);
                const avatarColor = AVATAR_COLORS[speaker as keyof typeof AVATAR_COLORS];
                const shouldFlash = !isScrolling && scrolledToFragmentIndex === index;
                return (
                  <Fragment
                    ref={(el: HTMLDivElement) => (fragmentRefs.current[index] = el)}
                    key={`fragment-${index}`}
                    color={avatarColor}
                    speaker={speakerName}
                    content={content}
                    shouldFlash={shouldFlash}
                  />
                );
              })}
            </S.TranscriptWrapper>
          );
        } catch (error) {
          console.error("Error rendering transcript", error);
          onError();
        }
      }
      return null;
    };

    return render();
  }
);

const Fragment = React.forwardRef(
  (
    { color, speaker, content, shouldFlash }: { color: any; speaker: string; content: string; shouldFlash: boolean },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <S.FragmentWrapper ref={ref}>
        <S.SpeakerWrapper>
          <Avatar size="small" color={color}>
            <Expert />
          </Avatar>
          <S.SpeakerTypography>{speaker}</S.SpeakerTypography>
        </S.SpeakerWrapper>
        <S.FragmentTypography shouldFlash={shouldFlash}>{content}</S.FragmentTypography>
      </S.FragmentWrapper>
    );
  }
);

export default TranscriptViewer;
