import { useEffect } from "react";
import { Sidebar } from "./Sidebar";
import { Topbar } from "./Topbar";
import { MainContent } from "./MainContent";
import * as S from "./AngleQuestionsView.styled";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";
import { useAnglesTypesFromProject } from "./helpers";

const AngleQuestionsView = ({ onSendNewMessage }: { onSendNewMessage: any }) => {
  const { setSelectedAngleTypeId } = useAngleQuestionsContext();
  const angleTypes = useAnglesTypesFromProject();
  const loading = angleTypes.length === 0;

  useEffect(
    function getFirstAngleQuestionThemes() {
      if (!loading) {
        setSelectedAngleTypeId(angleTypes.at(0)!.id);
      }
    },
    [angleTypes, loading, setSelectedAngleTypeId]
  );

  return (
    <S.Wrapper>
      <Sidebar loading={loading} />
      <S.RightSide>
        <Topbar loading={loading} onSendNewMessage={onSendNewMessage} />
        <MainContent />
      </S.RightSide>
    </S.Wrapper>
  );
};

export { AngleQuestionsView };
