import { Fragment, ChangeEvent, useCallback, useMemo, useState } from "react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import {
  Divider,
  Typography,
  Link,
  Tile,
  Icon,
  EllipsisText,
  Tooltip,
  Carousel,
  Skeleton,
  SelectSearch,
  SelectOption,
  SelectValue,
  Loading,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { Company, RightArrow, ThirdParty } from "@alphasights/alphadesign-icons";
import { useStyles } from "./VendorModuleContent.styles";
import { HitAction, TranscriptMention, VendorItem } from "@alphasights/portal-api-client";
import { vendors } from "views/ProjectSynthesisView/synthesisTypeGuards";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useNavigate } from "router-utils";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import {
  DeleteItemWithConfirmationBtn,
  EditableInput,
  EditStructuredModuleItemCTAs,
  MoreActions,
  RegenerationFailedToast,
  RevisionToggler,
} from "../components";
import { ExternalAlphaCompany } from "../../../../models/ExternalAlphaCompany";
import { ExternalAlphaCompaniesService } from "services/externalAlphaCompaniesService";

import { debounce } from "lodash";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { toString } from "components/InteractionsFilter/serializer";
import { v4 as uuid } from "uuid";
import { UrlParam } from "views/DeliverablesView/constants";
import { useEditItem } from "views/ProjectSynthesisView/hooks/useEditItem";
import { useEditVendorOperations } from "views/ProjectSynthesisView/hooks/useEditVendorOperations";
import { getTitleForTranscriptMention, sortByTargetAndAnchor } from "./helpers";
import _ from "lodash";

export const VendorModuleContent = () => {
  const { selectedModule, revision } = useProjectSynthesisContext();

  if (!revision || !selectedModule) return null;

  return <Render />;
};

const Render = () => {
  const [editItemsCount, setEditItemsCount] = useState(0);
  const styles = useStyles();

  const {
    revision,
    selectedRevisionIdx,
    synthesisLogHit,
    selectedModule,
    saveModuleChanges,
    isLatestRevisionSelected,
  } = useProjectSynthesisContext();
  const { addVendorItem } = useEditVendorOperations({ originalModule: selectedModule });

  const content = vendors(revision?.contents);

  const user = useCurrentUser();

  const { project } = useCurrentProjectContext();

  const handleNewVendor = (item: VendorItem) => {
    const updatedModule = addVendorItem(item, selectedRevisionIdx);
    updatedModule &&
      saveModuleChanges(updatedModule, undefined, true, `${item.companyName} added.`).then(() => {
        synthesisLogHit({
          action: HitAction.projectSynthesisEditModuleAddVendors,
          details: {
            revision: revision?.revision,
            moduleType: selectedModule?.contentType,
            vendor: item.companyName,
          },
          references: {
            moduleId: selectedModule?.id,
          },
        });
      });
  };

  const onEnterEditMode = useCallback(() => {
    setEditItemsCount((prev) => prev + 1);
  }, []);
  const onLeaveEditMode = useCallback(() => {
    setEditItemsCount((prev) => Math.max(0, prev - 1));
  }, []);

  if (revision?.status === "PROCESSING") {
    return <ProcessingModuleContent />;
  }

  if (revision?.status === "FAILED") {
    return <RegenerationFailedToast module={selectedModule!} />;
  }

  const orderedFeaturedVendors = sortByTargetAndAnchor(
    content.featuredVendors,
    project?.investmentTargetCompanies ?? [],
    project?.externalAnchorCompanies ?? []
  );

  return (
    <x.div {...styles.contentWrapper}>
      {editItemsCount === 0 && (
        <RevisionToggler display="flex" justifyContent="flex-end" position="absolute" right="0" top="0" zIndex="1" />
      )}
      {user?.enableAiInteractivity && !user?.consultancyClient && isLatestRevisionSelected && (
        <Fragment key={"add-vendor"}>
          <AddCard allVendors={[...orderedFeaturedVendors, ...content.emergingVendors]} onAddVendor={handleNewVendor} />
          <Divider mx={"-24px"} />
        </Fragment>
      )}
      {orderedFeaturedVendors.map((v) => (
        <Fragment key={v.companyName}>
          <VendorRender vendorItem={v} expanded onEnterEditMode={onEnterEditMode} onLeaveEditMode={onLeaveEditMode} />
          <Divider mx={"-24px"} />
        </Fragment>
      ))}

      {content.emergingVendors.length > 0 && (
        <>
          <Typography variant="body-large-em">Additional Vendors</Typography>
          <Divider mx={"-24px"} />
          {content.emergingVendors.map((v) => (
            <Fragment key={v.companyName}>
              <VendorRender vendorItem={v} onEnterEditMode={onEnterEditMode} onLeaveEditMode={onLeaveEditMode} />
              <Divider mx={"-24px"} />
            </Fragment>
          ))}
        </>
      )}
    </x.div>
  );
};

const VendorRender = ({
  vendorItem,
  expanded = false,
  onEnterEditMode,
  onLeaveEditMode,
}: {
  vendorItem: VendorItem;
  expanded?: boolean;
  onEnterEditMode?: () => void;
  onLeaveEditMode?: () => void;
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const {
    selectedModule,
    selectedRevisionIdx,
    saveInProgress,
    saveModuleChanges,
    vendorRefreshing,
  } = useProjectSynthesisContext();
  const { updateVendorItem, deleteVendorItem } = useEditVendorOperations({ originalModule: selectedModule });
  const { project } = useCurrentProjectContext();

  const onSaveChanges = useCallback(
    (vendorToSave: VendorItem) => {
      const updatedModule = updateVendorItem(vendorToSave, selectedRevisionIdx);
      return updatedModule
        ? saveModuleChanges(updatedModule, { editedItem: `${vendorToSave.companyName}` })
        : Promise.resolve();
    },
    [saveModuleChanges, selectedRevisionIdx, updateVendorItem]
  );

  const { mode, actualItem: vendor, enterEditMode, onEditItem, onCancelEdit, onSaveEdit } = useEditItem({
    item: vendorItem,
    onSaveChanges,
    onEnterEditMode,
    onLeaveEditMode,
  });

  const totalMentions = useMemo(() => {
    const uniqueTpdMentions = _.uniqBy(vendor.mentions?.filter((m) => m.thirdPartyDocumentId) ?? [], (m) =>
      m.advisorCompany && m.role ? `${m.advisorCompany}-${m.role}` : _.uniqueId("ungrouped-")
    );
    const interactionMentions = vendor.mentions?.filter((m) => m.interactionId) ?? [];

    return uniqueTpdMentions.length + interactionMentions.length;
  }, [vendor.mentions]);

  const onClickAllMentions = useCallback(() => {
    const search = new URLSearchParams({
      [UrlParam.deliverablesSearch]: vendor.companyName,
      transcriptType: "Transcript",
      f: toString({ groups: selectedModule!.angleIds }),
    });

    navigate(`/${project!.token}/experts/deliverables-view/?${search}`);
  }, [project, vendor, navigate, selectedModule]);

  const onDelete = useCallback(() => {
    const updatedModule = deleteVendorItem(vendorItem, selectedRevisionIdx);
    updatedModule &&
      saveModuleChanges(
        updatedModule,
        { deletedItem: `${vendorItem.companyName}` },
        true,
        `${vendorItem.companyName} deleted.`
      );
  }, [deleteVendorItem, saveModuleChanges, selectedRevisionIdx, vendorItem]);

  return (
    <x.div display="flex" flexDirection="column" gap="12px" data-testid={`vendor-item-${vendor.companyName}`}>
      <x.div {...styles.vendorWrapper}>
        <x.div>
          {vendor.companyLogo ? (
            <Tile variant="image" size="medium" image={vendor.companyLogo} />
          ) : (
            <Tile variant="icon" size="medium" icon={<Company data-testid="no-logo" />} />
          )}
        </x.div>
        <x.div {...styles.vendorDataColumn}>
          <x.div display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <x.div display="flex" flexDirection="column" gap="4px">
              <x.div display={"flex"} gap="10px" alignItems={"center"}>
                <Typography variant="body-large-em" data-testid="vendor-title">
                  {vendor.companyName}
                </Typography>
              </x.div>
              <x.div {...styles.vendorMentionsTotal}>
                {vendor.mentions && !vendor.isPending ? (
                  <>
                    <Typography variant="body-small-em" color="secondary">
                      {totalMentions === 0 && "Mentioned by experts"}
                      {totalMentions === 1 && "Mentioned by 1 expert"}
                      {totalMentions > 1 && `Mentioned by ${totalMentions} experts`}
                    </Typography>
                    {vendorRefreshing && <Loading size="xs" data-testid="spinner-refreshing-mentions" />}
                    {mode === Mode.VIEW && (
                      <Link
                        endIcon={<RightArrow />}
                        size="small"
                        onClick={onClickAllMentions}
                        fontWeight="600 !important"
                        data-testid="link-view-all-mentions"
                      >
                        View all mentions
                      </Link>
                    )}
                  </>
                ) : (
                  <Skeleton variant="noMargin" width="300px" height="16px" />
                )}
              </x.div>
            </x.div>
            {mode === Mode.EDIT && (
              <Tooltip title="Delete vendor" variant="dark">
                <DeleteItemWithConfirmationBtn
                  onDeleteItem={onDelete}
                  saveInProgress={saveInProgress}
                  tooltipText="Delete Vendor"
                  testId={`delete-vendor-${vendor.companyName}`}
                />
              </Tooltip>
            )}
            {mode === Mode.VIEW && (
              <MoreActions
                enterEditMode={enterEditMode}
                onDelete={onDelete}
                showEdit={expanded && !vendor.isPending}
                disabled={vendorRefreshing}
              />
            )}
          </x.div>
          {expanded && (
            <>
              <VendorSummary vendor={vendor} mode={mode} onEditItem={onEditItem} />
              {mode === Mode.VIEW && !vendor.isPending && (
                <Carousel shouldScrollOnFocus={false} {...styles.carousel}>
                  {vendor.mentions
                    ? vendor.mentions.map((m) => {
                        return <ExpertCard key={m.interactionId} mention={m} companyName={vendor.companyName} />;
                      })
                    : [1, 2, 3].map((i) => {
                        return <Skeleton key={i} variant="noMargin" width="340px" height="46px" />;
                      })}
                </Carousel>
              )}
            </>
          )}
        </x.div>
      </x.div>
      {mode === Mode.EDIT && (
        <EditStructuredModuleItemCTAs
          onCancelEdit={onCancelEdit}
          onSaveEdit={onSaveEdit}
          saveDisabled={saveInProgress}
        />
      )}
    </x.div>
  );
};

const VendorSummary = ({
  vendor,
  mode,
  onEditItem,
}: {
  vendor: VendorItem;
  mode: Mode;
  onEditItem: (updatedItem: VendorItem) => void;
}) => {
  const onEditSummary = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onEditItem({ ...vendor, summary: e.target.value });
    },
    [onEditItem, vendor]
  );

  if (vendor.isPending)
    return (
      <>
        {[1, 2, 3].map((i) => (
          <Skeleton variant="noMargin" width="100%" height="16px" />
        ))}
      </>
    );

  if (mode === Mode.VIEW) return <Typography data-testid="vendor-summary">{vendor.summary}</Typography>;

  return <EditableInput input={vendor.summary ?? ""} onChange={onEditSummary} />;
};

const ExpertCard = ({ companyName, mention }: { companyName: string; mention: TranscriptMention }) => {
  const styles = useStyles();

  const navigate = useNavigate();

  const { project } = useCurrentProjectContext();

  const { selectedModule, thirdPartyDocuments } = useProjectSynthesisContext();
  const document = thirdPartyDocuments?.find((doc) => doc.id === mention.thirdPartyDocumentId);
  const expertTitle = getTitleForTranscriptMention(mention, document);

  const onClick = useCallback(() => {
    const search = mention.thirdPartyDocumentId
      ? new URLSearchParams({
          selectedUploadedDocument: mention.thirdPartyDocumentId,
          [UrlParam.deliverablesSearch]: companyName,
          transcriptType: "Transcript",
        })
      : new URLSearchParams({
          selectedInteraction: mention.interactionId ?? "",
          [UrlParam.deliverablesSearch]: companyName,
          transcriptType: "Transcript",
          f: toString({ groups: selectedModule!.angleIds }),
        });

    navigate(`/${project!.token}/experts/deliverables-view/?${search}`);
  }, [mention.interactionId, mention.thirdPartyDocumentId, companyName, selectedModule, navigate, project]);

  return (
    <Tooltip title={`View mentions in transcript`} variant="dark">
      <x.div
        {...styles.expertCardWrapper}
        onClick={onClick}
        data-testid={`expert-mention-${mention.interactionId ?? mention.thirdPartyDocumentId}`}
      >
        <Tile variant="icon" size={"x-small"} color="tertiary" icon={<ThirdParty />} />
        <x.div {...styles.interactionContent}>
          <EllipsisText typographyProps={{ variant: "body-small" }} disableTooltip>
            {expertTitle}
          </EllipsisText>
        </x.div>
      </x.div>
    </Tooltip>
  );
};

const AddCard = ({
  allVendors,
  onAddVendor,
}: {
  allVendors: VendorItem[];
  onAddVendor: (item: VendorItem) => void;
}) => {
  const styles = useStyles();

  const { readOnly } = useProjectSynthesisContext();

  const existingVendors = new Set(allVendors.map(({ companyName }) => companyName));

  const [options, setOptions] = useState<ExternalAlphaCompany[]>([]);

  const searchCompaniesFunction = ExternalAlphaCompaniesService.search;

  const searchCompanies = debounce(async (value: string) => {
    setOptions([]);
    const data = value ? await searchCompaniesFunction(value) : [];
    setOptions(data.filter((it) => !existingVendors.has(it.name)));
  }, 200);

  const handleSelectedItem = useCallback(
    (value: SelectValue | SelectValue[]) => {
      const selectedCompany = options.find(({ name }) => name === value);
      selectedCompany &&
        onAddVendor({
          id: uuid(),
          cdsAlphaCompanyId: `${selectedCompany.id}`,
          companyLogo: selectedCompany.logo!!,
          companyName: selectedCompany.name!!,
          mentions: [],
          isPending: true,
          isPinned: true,
        });
    },
    [options, onAddVendor]
  );

  const handleItemRender = () => "";

  return (
    <x.div {...styles.vendorWrapper}>
      <x.div>
        <Tile variant="icon" size="medium" icon={<Company />} />
      </x.div>
      <x.div {...styles.vendorDataColumn} w="100%">
        <Typography variant="body-em" data-testid="vendor-title" {...styles.vendorAddTitle}>
          Add a vendor
        </Typography>
        <x.div {...styles.vendorMentionsTotal}>
          <>
            <Typography variant="body-small" {...styles.vendorAddInfo}>
              Search for a vendor to add it to your synthesis
            </Typography>
          </>
        </x.div>
        <SelectSearch
          placeholder="Search for a vendor"
          allowMultiple={false}
          onChange={handleSelectedItem}
          onInputValueChange={(value) => searchCompanies(value.trim())}
          hideResultsIfSearchEmpty={true}
          isClearButtonEnabled={false}
          customSelectedItemRendererInInput={handleItemRender}
          dataAttributes={{ "data-testid": "search-vendor" }}
          mt="2px"
          disabled={readOnly}
        >
          {options.length > 0
            ? options.map(({ name, logo }) => (
                <SelectOption key={name} value={name}>
                  <x.div display="flex" gap="8px" justifyContent="center">
                    <x.div {...styles.vendorLogo}>
                      {logo ? (
                        <x.img src={logo} maxW="100%" maxH="100%" />
                      ) : (
                        <Icon size="small" color="secondary">
                          <Company />
                        </Icon>
                      )}
                    </x.div>
                    <Typography variant="body">{name}</Typography>
                  </x.div>
                </SelectOption>
              ))
            : null}
        </SelectSearch>
      </x.div>
    </x.div>
  );
};

const ProcessingModuleContent = () => {
  return (
    <>
      <x.div display="flex" flexDirection="column" gap="16px" p="24px" data-testid="processing-module">
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>
    </>
  );
};
