const AVATAR_COLORS = {
  Interviewer: "base",
  Expert: "base03",
  "Expert 1": "base03",
  "Expert 2": "base04",
  "Expert 3": "base05",
  "Expert 4": "base06",
  "Expert 5": "base02",
  "Expert 6": "base02",
  "Expert 7": "base02",
  "Expert 8": "base02",
  "Expert 9": "base02",
};

const INTERVIEWER_SPEAKER = "Interviewer";

const PART_PARAM = "part";

export { AVATAR_COLORS, INTERVIEWER_SPEAKER, PART_PARAM };
