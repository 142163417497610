import { Icon, Skeleton } from "@alphasights/alphadesign-components";
import { Company } from "@alphasights/alphadesign-icons";
import styled from "styled-components";
import { th } from "@xstyled/styled-components";
import {
  COMPANY_LOGO_SIZE_LARGE,
  COMPANY_LOGO_SIZE_MEDIUM,
  COMPANY_LOGO_SIZE_SMALL,
  COMPANY_LOGO_SIZE_XLARGE,
} from "./company-logo-sizes";

export const CompanyLogoContainer = styled.div<{ size?: number }>`
  width: ${({ size }) => size ?? COMPANY_LOGO_SIZE_LARGE}px;
  height: ${({ size }) => size ?? COMPANY_LOGO_SIZE_LARGE}px;
  min-width: ${({ size }) => size ?? COMPANY_LOGO_SIZE_LARGE}px;

  border-radius: ${th.radius("8")};
  border: ${th.borderWidth("sm")} solid ${th.color("border-disabled")};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export type CompanyLogoComponentProps = {
  logoUrl?: string;
  loading?: boolean;
  size?: "large" | "medium" | "small" | "xlarge" | number;
} & React.HTMLAttributes<HTMLDivElement>;

export const CompanyLogoComponent = ({ logoUrl, loading, size = "large", ...props }: CompanyLogoComponentProps) => {
  const sizeMap = {
    xlarge: COMPANY_LOGO_SIZE_XLARGE,
    large: COMPANY_LOGO_SIZE_LARGE,
    medium: COMPANY_LOGO_SIZE_MEDIUM,
    small: COMPANY_LOGO_SIZE_SMALL,
  };
  const logoSize = typeof size === "number" ? size : sizeMap[size];

  return (
    <CompanyLogoContainer size={logoSize} {...props}>
      {loading && <Loading />}
      {!loading && logoUrl && <img src={logoUrl} alt="Company logo" />}
      {!loading && !logoUrl && (
        <Icon size="small">
          <Company />
        </Icon>
      )}
    </CompanyLogoContainer>
  );
};

const Loading = () => (
  <div>
    <Skeleton circle />
  </div>
);
