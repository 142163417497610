import { Button, Icon, ListOption, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Advisor, CalendarAvailable, Close, MoreVert } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { getStateColorFlyout, getStateName } from "components/AdvisorCard/stateFunctions";
import { TooltipStateDetail } from "components/InteractionsPage/TooltipStateDetail";
import { RelevantCompanyLogo } from "components/RelevantCompanyLogo";
import { addSeconds } from "date-fns";
import useOnClickOutside from "hooks/useOnClickHooks";
import { CurrentTimezone, FormattedDateTime, FormattedTimeRange, parseISO } from "providers/TimezoneProvider";
import * as React from "react";
import { forwardRef, useContext, useLayoutEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { AttendeesList, AttendeesSelect, LoadingBar } from "../AttendeesSection";
import { CalendarContext } from "../CalendarContext";
import { setSelectedAttendees, updateInitialAttendees } from "../reducer";

export const CalendarPopup = forwardRef(({ children, ...props }, ref) => {
  const { spacing } = useThemeTokens();

  const styles = {
    w: "400px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    boxShadow: "0px 2px 16px 0px #001D3A1F",
    borderRadius: spacing.inner.base02,
  };

  return (
    <x.div ref={ref} {...styles} {...props}>
      {children}
    </x.div>
  );
});

export const PopupMenus = ({ advisorName = "", showMenu = false, items, onItemClick, onClose }) => {
  const anchorEl = useRef();
  const {
    spacing: { inner },
  } = useThemeTokens();
  const [popoverOpen, setPopoverOpen] = React.useState(null);
  const [popoverRef, setPopoverRef] = React.useState();

  const onMenuOpen = () => {
    setPopoverOpen(!popoverOpen);
  };

  const { styles, attributes } = usePopper(anchorEl.current, popoverRef, {
    placement: "bottom-end",
  });

  useOnClickOutside({ current: popoverRef }, () => setPopoverOpen(false));

  return (
    <x.div
      display="flex"
      justifyContent="space-between"
      p={inner.base04}
      borderBottom={`1px solid #E7E8EA`}
      alignItems="center"
      mb={inner.base04}
    >
      <Typography variant="body-em" color="strong">
        {advisorName}
      </Typography>
      <x.div display="flex" alignItems="center">
        {showMenu && (
          <>
            <x.div ref={anchorEl}>
              <Icon
                onClick={onMenuOpen}
                color="secondary"
                size="small"
                active={popoverOpen}
                data-testid="popup-menu-handle"
              >
                <MoreVert />
              </Icon>
            </x.div>
            {popoverOpen &&
              createPortal(
                <x.div
                  id="popup-menu"
                  ref={setPopoverRef}
                  style={{ ...styles.popper, zIndex: 200 }}
                  {...attributes.popper}
                  backgroundColor="white"
                  padding={inner.base02}
                  borderRadius={inner.base02}
                  boxShadow="0px 1px 5px rgba(34, 34, 34, 0.2)"
                >
                  {items.map((item, ix) => (
                    <ListOption
                      key={item}
                      type="text"
                      label={item}
                      onChange={() => {
                        setPopoverOpen(false);
                        onItemClick(item);
                      }}
                    ></ListOption>
                  ))}
                </x.div>,
                document.querySelector("body")
              )}
          </>
        )}
        <x.div ml={inner.base02}>
          <Icon onClick={onClose} color="secondary" size="small" data-testid="popup-close">
            <Close />
          </Icon>
        </x.div>
      </x.div>
    </x.div>
  );
};

export const CalendarPopupFooter = ({ children }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      p={inner.base04}
      mt={inner.base04}
      gap={inner.base04}
      borderTop={`1px solid #E7E8EA`}
    >
      {children}
    </x.div>
  );
};

const iconSize = { name: "medium", size: "20px" };

export const PopupDataAreaLeftPad = ({ children, marginTop, ...props }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div display="flex" mt={marginTop ? inner[marginTop] : undefined} {...props}>
      <x.div w={iconSize.size} flexShrink={0} />
      <x.div flexGrow={1}>{children}</x.div>
    </x.div>
  );
};

export const PopupDataArea = ({ icon, firstLine, children, addMarginTop = true, ...props }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div
      display="flex"
      flexDirection="column"
      paddingLeft={inner.base04}
      paddingRight={inner.base04}
      mt={addMarginTop ? inner.base04 : null}
      {...props}
    >
      <x.div display="flex" alignItems="start">
        {icon && (
          <Icon size={iconSize.name} color="secondary">
            {icon}
          </Icon>
        )}
        <x.div flex="1" ml={icon ? inner.base02 : null} overflow="hidden">
          {firstLine}
        </x.div>
      </x.div>
      {icon ? <PopupDataAreaLeftPad children={children} ml={inner.base02} /> : children}
    </x.div>
  );
};

export const ExpertData = ({ interaction, onOpenFlyout, requestPending }) => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();

  return (
    <PopupDataArea
      firstLine={
        <x.div
          onClick={onOpenFlyout}
          cursor={onOpenFlyout ? "pointer" : null}
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <x.div display="flex" alignItems="center" gap={inner.base}>
            <TooltipStateDetail interaction={interaction} pendingRequest={requestPending}>
              <Typography
                variant="body-large-em"
                color={getStateColorFlyout(interaction.state, color)}
                mr={inner.base}
                textTransform="capitalize"
                component="span"
                data-testid="interaction-state"
              >
                {getStateName({
                  ...interaction,
                  showCalendarView: true,
                  pendingRequest: requestPending,
                })}
              </Typography>
            </TooltipStateDetail>
            {" • "}
            <RelevantCompanyLogo projectToken={interaction.projectToken} interactionId={interaction.id} size="large" />
            <Typography variant="body-large-em" color="strong" ml={inner.base} component="span">
              {interaction.companyName} - {interaction.role}
            </Typography>
          </x.div>
        </x.div>
      }
      addMarginTop={false}
    ></PopupDataArea>
  );
};

export const SelectAttendees = ({ interaction, onSave }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { state, dispatch: dispatchCalendar } = useContext(CalendarContext);
  const [hasChanges, setHasChanges] = useState(false);

  const initialAttendees = useMemo(() => state.popup.attendees.initial || [], [state.popup.attendees.initial]);
  const currentAttendees = useMemo(() => state.popup.attendees.selected || [], [state.popup.attendees.selected]);

  const setCurrentAttendees = (val) => dispatchCalendar(setSelectedAttendees(val));

  const removeAttendee = (attendee) => () => setCurrentAttendees(currentAttendees.filter((att) => att !== attendee));

  const toggleOptionalAttendee = (attendee) => () =>
    setCurrentAttendees(
      currentAttendees.map((att) => (att !== attendee ? att : { ...att, optional: !attendee.optional }))
    );

  useLayoutEffect(() => {
    const initial = initialAttendees.map(JSON.stringify);
    const current = currentAttendees.map(JSON.stringify);

    const hasChanges = current.length !== initial.length || current.find((attendees) => !initial.includes(attendees));

    setHasChanges(hasChanges);
  }, [initialAttendees, currentAttendees]);

  const saveAndResetInitialAttendeesState = () => {
    onSave({ id: interaction.id, attendees: state.popup.attendees.selected });
    dispatchCalendar(updateInitialAttendees(state.popup.attendees.selected));
  };

  if (!interaction.autoBookEnabled) return null;

  return (
    <PopupDataArea
      icon={<Advisor />}
      data-testid="attendees-list-select"
      firstLine={
        state.popup.attendees.isLoading ? (
          <LoadingBar />
        ) : (
          <AttendeesSelect
            options={state.popup.attendees.options}
            currentAttendees={currentAttendees}
            setCurrentAttendees={setCurrentAttendees}
          />
        )
      }
    >
      {!state.popup.attendees.isLoading && (
        <x.div pt={inner.base02}>
          <AttendeesList
            attendees={currentAttendees}
            removeAttendee={removeAttendee}
            toggleOptionalAttendee={toggleOptionalAttendee}
          />
        </x.div>
      )}

      {hasChanges && onSave && (
        <Button
          variant="primary"
          data-testid="save-attendees-button"
          onClick={saveAndResetInitialAttendeesState}
          mt={inner.base04}
        >
          Save
        </Button>
      )}
    </PopupDataArea>
  );
};

export const ScheduledCallTime = ({ date, duration, addMarginTop = true, icon, label = "" }) => {
  const asDate = parseISO(date);

  return (
    <PopupDataArea
      addMarginTop={addMarginTop}
      icon={icon || <CalendarAvailable />}
      firstLine={
        <>
          {label && <Typography color="secondary">{label}</Typography>}
          <Typography variant="body" color="strong">
            <FormattedDateTime date={asDate} format="iiii d MMM, " />
            <FormattedTimeRange start={asDate} end={addSeconds(asDate, duration || 3600)} />

            <CurrentTimezone formatOnly format=" (O)" />
          </Typography>
        </>
      }
    />
  );
};
