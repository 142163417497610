import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { compact, uniqBy } from "lodash";
import { useMemo } from "react";

export const useAnglesTypesFromProject = () => {
  const { project } = useCurrentProjectContext();
  const uniqueAngleTypes = useMemo(() => {
    const parentAngles =
      uniqBy(
        project?.angles.map((angle) => (angle.parent ? angle.parent : angle)),
        "id"
      ) ?? [];
    const angleTypes = uniqBy(compact(parentAngles.map((angle) => angle.type)), "id");

    return angleTypes.map((angleType) => {
      const anglesFromThisType = parentAngles.filter((angle) => angle.type?.id === angleType.id);
      return {
        id: angleType.id,
        name: anglesFromThisType.length > 1 ? angleType.name : anglesFromThisType.at(0)?.title ?? angleType.name,
      };
    });
  }, [project?.angles]);

  return uniqueAngleTypes;
};
