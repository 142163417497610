import { memo, useCallback, useEffect } from "react";
import { x } from "@xstyled/styled-components";
import { useStyles } from "./DeliverablesPage.styles";
import { NewTopBar } from "./TopBar/TopBar";
import { ViewType } from "../AiSummaries/AiSummaries.types";
import { TranscriptTab } from "./TranscriptTab/TranscriptTab";
import {
  InteractionDeliverablesProvider,
  useInteractionDeliverablesContext,
} from "providers/InteractionDeliverableProvider";
import { AudioPlayer } from "../AudioPlayer";
import { Divider, Typography } from "@alphasights/alphadesign-components";
import { formatDistanceToNow, parseISO } from "date-fns";
import { RecordingToggler } from "../RecordingToggler";
import { SummaryTab } from "./SummaryTab/SummaryTab";
import { MentionsTab } from "./MentionTab/MentionsTab";
import { QuestionsTab } from "./QuestionsTab/QuestionsTab";

export interface DeliverablesPageProps {
  interaction: Interaction;
  appliedKeywords?: string[];
  initialTranscriptType?: ViewType;
  privateLibraryId?: string;
}

const DeliverablesPage = memo(
  (props: DeliverablesPageProps) => {
    return (
      <InteractionDeliverablesProvider keywords={props.appliedKeywords ?? []} {...props}>
        <DeliverablesPageNoCtx />
      </InteractionDeliverablesProvider>
    );
  },
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

const DeliverablesPageNoCtx = () => {
  const styles = useStyles();
  const {
    audioPlayer,
    canPlayAudio,
    availableRecordings,
    currentRecording,
    selectRecording,
    hasTranscripts,
    interaction,
    selectedView,
    setSelectedView,
    sentences,
    privateLibraryConversation,
  } = useInteractionDeliverablesContext()!!;

  const toggleAudio = useCallback(() => {
    audioPlayer.toggle();
    audioPlayer.explicitlyClose();
  }, [audioPlayer]);

  useEffect(
    function showHidePlayerOnTabChange() {
      if (audioPlayer.controls.playing) {
        audioPlayer.showPlayer();
      } else if (audioPlayer.explicitlyClosed || selectedView !== ViewType.Transcript) {
        audioPlayer.hidePlayer();
      } else {
        if (selectedView === ViewType.Transcript) audioPlayer.showPlayer();
      }
    },
    [selectedView] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <x.div data-testid="deliverables-main-content" {...styles.wrapper}>
      {audioPlayer.render}
      <NewTopBar
        interaction={interaction}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        audioPlayer={!hasTranscripts && audioPlayer}
        currentRecording={hasTranscripts ? null : currentRecording}
        showTabs={hasTranscripts}
        privateLibraryConversation={privateLibraryConversation}
        rightAccessory={
          canPlayAudio && (
            <RecordingToggler
              togglePlayer={toggleAudio}
              showingPlayer={audioPlayer.visible}
              currentRecording={currentRecording}
              setCurrentRecording={selectRecording}
              availableRecordings={availableRecordings}
              selectedTranscriptView={selectedView}
              controlsProps={audioPlayer.controls}
              progressBarProps={audioPlayer.progress}
            />
          )
        }
      />
      <x.div {...styles.mainContent} data-testid="tab-content-wrapper">
        <x.div
          {...styles.tabContentWrapper}
          display={selectedView === ViewType.Summary ? "block" : "none"}
          data-summary-scrollable-area
        >
          <SummaryTab />
        </x.div>
        <x.div
          {...styles.tabContentWrapper}
          display={selectedView === ViewType.Questions ? "block" : "none"}
          data-questions-scrollable-area
        >
          <QuestionsTab />
        </x.div>
        <x.div
          {...styles.tabContentWrapper}
          display={selectedView === ViewType.Mentions ? "block" : "none"}
          data-mentions-area
        >
          <MentionsTab />
        </x.div>
        <x.div
          {...styles.tabContentWrapper}
          display={selectedView === ViewType.Transcript ? "block" : "none"}
          data-transcript-scrollable-area
          data-testid="transcript-scrollable-area"
        >
          <TranscriptTab />
        </x.div>
      </x.div>
      <Divider />
      {audioPlayer.visible && selectedView === ViewType.Transcript && (
        <x.div {...styles.playerWrapper}>
          <AudioPlayer
            enableRefocus={Boolean(sentences[0]?.start)}
            controlsProps={audioPlayer.controls}
            progressBarProps={audioPlayer.progress}
            onClose={toggleAudio}
            // @ts-ignore
            progressBarChildren={
              currentRecording?.expiresAt && (
                <Typography variant="body-small" color="assistive">
                  Audio expires in {formatDistanceToNow(parseISO(currentRecording.expiresAt!!))}
                </Typography>
              )
            }
          />
        </x.div>
      )}
    </x.div>
  );
};

export { DeliverablesPage, DeliverablesPageNoCtx };
