import { useEffect, useState } from "react";
import * as React from "react";
import { Alert, ContentCard, IconButton, Skeleton, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import * as S from "./AngleCards.styled";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";
import useBrowserStorage, { StorageType } from "hooks/useBrowserStorage";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { Info } from "@alphasights/alphadesign-icons";
import { useAnglesTypesFromProject } from "views/AngleQuestionsView/helpers";

export const AngleCards = ({ loading }: { loading: boolean }) => {
  const [showAlert, setShowAlert] = React.useState(true);
  const { project } = useCurrentProjectContext();

  const [dismissedQuestionViewAlerts, setDismissedQuestionViewAlerts] = useBrowserStorage<string[]>(
    "dismissedQuestionViewAlerts",
    [],
    StorageType.LOCAL
  );

  useEffect(
    function hideAlertIfDismissed() {
      dismissedQuestionViewAlerts.includes(project?.token) && setShowAlert(false);
    },
    [project, dismissedQuestionViewAlerts]
  );

  const onCloseAlert = () => {
    setDismissedQuestionViewAlerts([...dismissedQuestionViewAlerts, project?.token]);
    setShowAlert(false);
  };

  return (
    <S.Wrapper>
      {loading ? (
        <>
          <SidebarCardLoading />
          <SidebarCardLoading />
          <SidebarCardLoading />
        </>
      ) : (
        <>
          {showAlert && (
            <Alert size="small" onClose={() => onCloseAlert()}>
              Questions are AI-generated and should not be asked if in violation of any applicable regulations, terms,
              or policies.
            </Alert>
          )}
          <QuestionAngles />
        </>
      )}
    </S.Wrapper>
  );
};

const QuestionAngles = () => {
  const { angleQuestionsCount, selectedAngleTypeId } = useAngleQuestionsContext();
  const angles = useAnglesTypesFromProject();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const ref = React.useRef(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <S.AngleCardTitleWrapper>
        <Typography variant="body-small-em" color="secondary">
          Question sets
        </Typography>
        <IconButton variant="ghost" size="small" onMouseEnter={handlePopoverOpen}>
          <Info />
        </IconButton>
        <S.DisclaimerPopover
          size="small"
          closeOnMouseLeave={true}
          ref={ref}
          anchorEl={anchorEl}
          open={open}
          onClose={handlePopoverClose}
          leaveDelay={500}
        >
          <Typography variant="body-small" color="strong">
            AlphaSights does not accept any responsibility or liability, including with respect to reliability or
            legality, for questions suggested below. Client is responsible for reviewing all questions and ensuring that
            any questions used in an AlphaSights Transaction are in compliance with Client’s contractual, legal and
            regulatory obligations.
          </Typography>
        </S.DisclaimerPopover>
      </S.AngleCardTitleWrapper>
      {angles.map((angle) => (
        <AngleCard
          key={angle.id}
          angle={angle}
          questionCount={angleQuestionsCount.find((c) => c.angleTypeId === angle.id)?.count ?? 0}
          selected={angle.id === selectedAngleTypeId}
        />
      ))}
    </>
  );
};

const AngleCard = ({
  angle,
  questionCount,
  selected,
}: {
  angle: AngleType;
  questionCount: number;
  selected: boolean;
}) => {
  const { setSelectedAngleTypeId, angleQuestionsCountLoading } = useAngleQuestionsContext();

  return (
    <ContentCard
      onClick={() => setSelectedAngleTypeId(angle.id)}
      cursor="pointer"
      selected={selected}
      data-testid={`angle-card-${angle.name}`}
    >
      <x.div display="flex" justifyContent="space-between">
        <Typography color="strong">{angle.name}</Typography>
        {angleQuestionsCountLoading ? (
          <S.AngleQuestionCountLoading />
        ) : (
          questionCount > 0 && (
            <Typography color="assistive">
              {`${questionCount} question${questionCount !== 1 ? "s" : ""} added`}
            </Typography>
          )
        )}
      </x.div>
    </ContentCard>
  );
};

const SidebarCardLoading = () => (
  <S.SidebarCardLoadingWrapper>
    <x.div w="70%" maxW="200px">
      <Skeleton variant="noMargin" width="100%" height="20px" />
    </x.div>
    <x.div w="30%" maxW="130px">
      <Skeleton variant="noMargin" width="100%" height="20px" />
    </x.div>
  </S.SidebarCardLoadingWrapper>
);
