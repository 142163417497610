export enum AngleQuestionType {
  AI = "AI",
  Client = "CLIENT",
}

export interface AngleQuestionTheme {
  id: number;
  title: string;
  type: AngleQuestionType;
  questions: AngleQuestion[];
  angleTypeId: number;
}

export interface AngleQuestion {
  id: number;
  text: string;
  type: AngleQuestionType;
}

export interface AngleQuestionCount {
  angleTypeId: number;
  count: number;
}

export const ANGLE_TYPES = {
  TargetCompany: "Target Company",
  IndustryAuthorities: "Industry Authorities",
  EndCustomers: "End Customers",
  Suppliers: "Suppliers",
  Competitors: "Competitors",
  Intermediaries: "Intermediaries",
  Other: "Other",
};
