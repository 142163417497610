export const getIntervalledColors = (colorsArray: string[], numElements: number) => {
  if (!Array.isArray(colorsArray) || colorsArray.length === 0 || numElements <= 0) {
    return [];
  }

  if (numElements === 1) {
    return [colorsArray[0]];
  }

  // Since we have 18 colors defined to this type of graph and we want to avoid
  // the huge contrast between the first and last colors, we will use only the
  // odd indexes plus the first color.
  // We defined 6 as it seems to be the minimum reasonable number to avoid huge constrasts.
  // This way we will have a better distribution of colors.
  if (numElements <= 6) {
    const selectedColors = colorsArray.filter((_, index) => index % 3 === 0);
    return selectedColors.slice(0, numElements);
  }

  if (numElements >= colorsArray.length) {
    return [...colorsArray];
  }

  const result = [colorsArray[0]];
  const lastIndex = colorsArray.length - 1;

  // Gets the interval between the colors
  const step = lastIndex / (numElements - 1);

  for (let i = 1; i < numElements - 1; i++) {
    const index = Math.round(i * step);
    result.push(colorsArray[index]);
  }

  result.push(colorsArray[lastIndex]);
  return result;
};

//adds repeated colors in case the number of colors is less than the number of elements
export const getRepeatingColors = (colors: string[], length: number): string[] => {
  if (length <= colors.length) return colors;

  const repeatedColors = [];
  for (let i = 0; i < length; i++) {
    repeatedColors.push(colors[i % colors.length]);
  }
  return repeatedColors;
};
