import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { hasOnlySurveyWorkstreams } from "components/InteractionsPage/helpers/Workstreams";
import { AngleQuestion, AngleQuestionCount, AngleQuestionTheme, AngleQuestionType } from "models/AngleQuestions";
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { angleQuestionsService } from "services/angleQuestionsService";
import { useCurrentProjectContext } from "./CurrentProjectProvider";
import { Project, ProjectFeature } from "@alphasights/portal-api-client";

interface AngleQuestionsContextState {
  enableAngleQuestions: boolean;
  getAngleQuestionThemes: (angleTypeId: number) => Promise<AngleQuestionTheme[]>;
  selectedAngleTypeId?: number;
  setSelectedAngleTypeId: (angleTypeId?: number) => void;
  angleQuestionsCount: AngleQuestionCount[];
  angleQuestionsCountLoading: boolean;
  copyAngleThemeQuestions: (themeId: number[]) => Promise<void>;
  getAngleThemeQuestionsText: (themeId: number[]) => string;
  updateProjectBrief: (brief: string) => Promise<String> | Promise<string | undefined>;
  selectedAngleQuestionThemes: AngleQuestionTheme[];
  deleteAngleQuestion: (questionId: number) => Promise<void>;
  deleteAngleQuestionTheme: (themeId: number) => Promise<void>;
  angleQuestionThemesLoading: boolean;
  addAngleQuestion: (themeId: number, question: string) => Promise<AngleQuestion>;
  updateAngleQuestion: (questionId: number, question: string) => Promise<AngleQuestion>;
  reorderAngleQuestionThemes: (angleThemeId: number, newPosition: number) => Promise<void>;
  reorderAngleQuestions: (angleThemeId: number, questionId: number, newPosition: number) => Promise<void>;
  createOrUpdateAngleQuestionTheme: (themeId: number, title: string) => Promise<AngleQuestionTheme>;
  addAngleQuestionTheme: () => Promise<AngleQuestionTheme | undefined>;
  isAddingAngleQuestionTheme: boolean;
  editingQuestionThemeId: number | undefined;
  setEditingQuestionTheme: (theme: AngleQuestionTheme) => void;
  questionThemeTitle: string;
  readOnly: boolean;
  addingQuestionThemeId: number | undefined;
  setAddingQuestionThemeId: (themeId: number | undefined) => void;
  editingQuestionId: number | undefined;
  setEditingQuestion: (question: AngleQuestion) => void;
  questionText: string;
  setQuestionText: (questionText: string) => void;
  setQuestionThemeTitle: (title: string) => void;
}

export interface AngleQuestionsContextProps {
  service?: typeof angleQuestionsService;
  project?: Project;
  children: ReactNode;
}

const AngleQuestionsContext = React.createContext<undefined | AngleQuestionsContextState>(undefined);

export const AngleQuestionsProvider = ({
  service = angleQuestionsService,
  project,
  children,
}: AngleQuestionsContextProps) => {
  const { isFeatureDisabled } = useCurrentProjectContext();
  const { isMobile } = useCheckScreen();
  const currentUser = useCurrentUser() as (User & { questionsViewEnabled: boolean }) | undefined;
  const isSurveyOnlyProject = useMemo(() => project && hasOnlySurveyWorkstreams(project), [project]);
  const projectToken = project?.token;
  const enableAngleQuestions = !!(currentUser?.questionsViewEnabled && !isSurveyOnlyProject && !isMobile);
  const [angleQuestionsCount, setAngleQuestionsCount] = useState<AngleQuestionCount[]>([]);
  const [angleQuestionsCountLoading, setAngleQuestionsCountLoading] = useState<boolean>(true);
  const [selectedAngleTypeId, setSelectedAngleTypeId] = React.useState<number | undefined>();
  const [selectedAngleQuestionThemes, setSelectedAngleQuestionThemes] = React.useState<AngleQuestionTheme[]>([]);
  const [angleQuestionThemesLoading, setAngleQuestionThemesLoading] = useState(true);

  const [editingQuestionThemeId, setEditingQuestionThemeId] = useState<number | undefined>();
  const [questionThemeTitle, setQuestionThemeTitle] = useState<string>("");

  const [editingQuestionId, setEditingQuestionId] = useState<number | undefined>();
  const [questionText, setQuestionText] = useState<string>("");

  const [addingQuestionThemeId, setAddingQuestionThemeId] = useState<number | undefined>();
  const [isAddingAngleQuestionTheme, setIsAddingAngleQuestionTheme] = useState(false);

  const updateAngleQuestionsCount = useCallback((angleTypeId: number, amount: number) => {
    setAngleQuestionsCount((prev) => {
      const existingIndex = prev.findIndex((count) => count.angleTypeId === angleTypeId);

      if (existingIndex === -1) {
        return [...prev, { angleTypeId: angleTypeId, count: amount }];
      }

      return prev.map((angleQuestionCount, index) =>
        index === existingIndex
          ? { ...angleQuestionCount, count: angleQuestionCount.count + amount }
          : angleQuestionCount
      );
    });
  }, []);

  const addAngleQuestion = useCallback(
    (themeId: number, text: string) => {
      return service.addAngleQuestion(themeId, text).then((angleQuestion) => {
        setSelectedAngleQuestionThemes((prev) =>
          prev.map((theme) =>
            theme.id === themeId
              ? {
                  ...theme,
                  questions: [...theme.questions, angleQuestion],
                }
              : theme
          )
        );

        const angleTypeId = selectedAngleQuestionThemes.find((theme) => theme.id === themeId)!.angleTypeId;
        updateAngleQuestionsCount(angleTypeId, 1);
        setAddingQuestionThemeId(undefined);
        setQuestionText("");
        return Promise.resolve(angleQuestion);
      });
    },
    [selectedAngleQuestionThemes, service, updateAngleQuestionsCount]
  );

  const cancelAddQuestionTheme = useCallback(() => {
    if (!isAddingAngleQuestionTheme) return;
    setSelectedAngleQuestionThemes((prev) => prev.filter((theme) => theme.id !== -1));
    setIsAddingAngleQuestionTheme(false);
    setEditingQuestionThemeId(undefined);
  }, [isAddingAngleQuestionTheme]);

  const updateAngleQuestion = useCallback(
    (questionId: number, text: string) => {
      return service.updateAngleQuestion(questionId, text).then((angleQuestion) => {
        setSelectedAngleQuestionThemes((prev) =>
          prev.map((theme) => ({
            ...theme,
            questions: theme.questions.map((question) =>
              question.id === questionId ? { ...question, text } : question
            ),
          }))
        );
        setQuestionText("");
        setEditingQuestionId(undefined);
        return Promise.resolve(angleQuestion);
      });
    },
    [service]
  );

  const createOrUpdateAngleQuestionTheme = useCallback(
    (themeId: number, title: string) => {
      const saveOperation =
        themeId === -1
          ? service.addAngleQuestionTheme(project!!.token, selectedAngleTypeId!, title)
          : service.updateAngleQuestionTheme(project!!.token, themeId, title);

      return saveOperation.then((savedTheme) => {
        if (themeId === -1) {
          setSelectedAngleQuestionThemes((prev) => [...prev.filter((theme) => theme.id !== -1), savedTheme]);
        } else {
          setSelectedAngleQuestionThemes((prev) =>
            prev.map((theme) => (theme.id === themeId ? { ...savedTheme } : theme))
          );
        }
        setEditingQuestionThemeId(undefined);
        setIsAddingAngleQuestionTheme(false);
        return Promise.resolve(savedTheme);
      });
    },
    [project, selectedAngleTypeId, service]
  );

  const handleUnsavedChanges = useCallback(() => {
    if (editingQuestionThemeId && questionThemeTitle) {
      return createOrUpdateAngleQuestionTheme(editingQuestionThemeId, questionThemeTitle);
    }

    if (editingQuestionId && questionText) {
      return updateAngleQuestion(editingQuestionId, questionText);
    }

    if (addingQuestionThemeId && questionText) {
      return addAngleQuestion(addingQuestionThemeId, questionText);
    }
    return Promise.resolve();
  }, [
    addAngleQuestion,
    addingQuestionThemeId,
    createOrUpdateAngleQuestionTheme,
    editingQuestionId,
    questionText,
    editingQuestionThemeId,
    questionThemeTitle,
    updateAngleQuestion,
  ]);

  const handleSetEditingQuestion = useCallback(
    (question: AngleQuestion) => {
      handleUnsavedChanges().then(() => {
        setEditingQuestionId(question.id);
        setQuestionText(question.text);
        setAddingQuestionThemeId(undefined);
        setEditingQuestionThemeId(undefined);
        setQuestionThemeTitle("");
        cancelAddQuestionTheme();
      });
    },
    [cancelAddQuestionTheme, handleUnsavedChanges]
  );

  const handleAddingQuestionThemeId = useCallback(
    (themeId: number | undefined) => {
      handleUnsavedChanges().then(() => {
        setAddingQuestionThemeId(themeId);
        if (themeId !== undefined) {
          setEditingQuestionId(undefined);
          setEditingQuestionThemeId(undefined);
          setQuestionThemeTitle("");
          cancelAddQuestionTheme();
        }
      });
    },
    [cancelAddQuestionTheme, handleUnsavedChanges]
  );

  const handleSetEditingQuestionTheme = useCallback(
    (theme: AngleQuestionTheme) => {
      handleUnsavedChanges().then(() => {
        setAddingQuestionThemeId(undefined);
        setIsAddingAngleQuestionTheme(false);
        setEditingQuestionId(undefined);
        cancelAddQuestionTheme();
        setEditingQuestionThemeId(theme.id);
        setQuestionThemeTitle(theme.title);
      });
    },
    [cancelAddQuestionTheme, handleUnsavedChanges]
  );

  const handleSetIsAddingAngleQuestionTheme = useCallback((isAdding: boolean) => {
    setIsAddingAngleQuestionTheme(isAdding);
    setEditingQuestionThemeId(-1);
    setQuestionThemeTitle("");
    if (isAdding) {
      setEditingQuestionId(undefined);
      setAddingQuestionThemeId(undefined);
    }
  }, []);

  const readOnly = useMemo(() => {
    return isFeatureDisabled(ProjectFeature.EditQuestionsTab) || (project != null && project.inactive);
  }, [isFeatureDisabled, project]);

  const getAngleQuestionThemes = useCallback(
    (angleTypeId: number) => {
      return projectToken ? service.findAngleQuestionThemes(projectToken, angleTypeId) : Promise.resolve([]);
    },
    [projectToken, service]
  );

  const updateProjectBrief = useCallback(
    (brief: string) => {
      return projectToken ? service.updateBrief(projectToken, brief) : Promise.resolve(project?.clientInstructions);
    },
    [project, projectToken, service]
  );

  const deleteAngleQuestion = useCallback(
    (questionId: number) => {
      return service.deleteAngleQuestion(questionId).then(() => {
        setSelectedAngleQuestionThemes((prev) =>
          prev.map((questionTheme) => ({
            ...questionTheme,
            questions: questionTheme.questions.filter((question) => question.id !== questionId),
          }))
        );

        const theme = selectedAngleQuestionThemes.find((theme) => theme.questions.some((q) => q.id === questionId))!;
        updateAngleQuestionsCount(theme.angleTypeId, -1);
        setEditingQuestionId(undefined);
      });
    },
    [selectedAngleQuestionThemes, service, updateAngleQuestionsCount]
  );

  const deleteAngleQuestionTheme = useCallback(
    (themeId: number) => {
      const deleteOperation =
        themeId === -1 ? Promise.resolve() : service.deleteAngleQuestionTheme(project!.token, themeId);
      return deleteOperation.then(() => {
        const deletedTheme = selectedAngleQuestionThemes.find((theme) => theme.id === themeId);
        setAngleQuestionsCount((prev) =>
          prev.map((angleQuestionCount) => ({
            ...angleQuestionCount,
            count:
              angleQuestionCount.count -
              (deletedTheme?.angleTypeId === angleQuestionCount.angleTypeId ? deletedTheme.questions.length : 0),
          }))
        );

        setSelectedAngleQuestionThemes((prev) => prev.filter((theme) => theme.id !== themeId));
        setIsAddingAngleQuestionTheme(false);
        setEditingQuestionThemeId(undefined);
      });
    },
    [project, selectedAngleQuestionThemes, service]
  );

  const getAngleThemeQuestionsText = useCallback(
    (themeIds: number[]): string => {
      const themes = selectedAngleQuestionThemes.filter((t) => themeIds.includes(t.id));
      if (themes.length === 0) return "";

      const text = themes
        .map((theme) => `${theme.title}\n${theme.questions.map((q, index) => `  ${index + 1}. ${q.text}`).join("\n")}`)
        .join("\n\n");

      return text;
    },
    [selectedAngleQuestionThemes]
  );

  const copyAngleThemeQuestions = useCallback(
    (themeIds: number[]): Promise<void> => {
      const textToCopy = getAngleThemeQuestionsText(themeIds);
      return navigator.clipboard.writeText(textToCopy);
    },
    [getAngleThemeQuestionsText]
  );

  const reorderAngleQuestionThemes = useCallback(
    (angleThemeId: number, newPosition: number) => {
      const reorderedThemes = Array.from(selectedAngleQuestionThemes);
      const movedThemeIndex = reorderedThemes.findIndex((theme) => theme.id === angleThemeId);
      const [movedTheme] = reorderedThemes.splice(movedThemeIndex, 1)!;
      reorderedThemes.splice(newPosition, 0, movedTheme);
      setSelectedAngleQuestionThemes(reorderedThemes);

      return service.updateAngleQuestionThemeOrder(project!.token, angleThemeId, newPosition);
    },
    [service, project, selectedAngleQuestionThemes]
  );

  const reorderAngleQuestions = useCallback(
    (angleThemeId: number, questionId: number, newPosition: number) => {
      const selectedAngleQuestionTheme = selectedAngleQuestionThemes.find((theme) => theme.id === angleThemeId)!;
      const reorderedQuestions = Array.from(selectedAngleQuestionTheme.questions);
      const movedQuestionIndex = reorderedQuestions.findIndex((question) => question.id === questionId);
      const [movedQuestion] = reorderedQuestions.splice(movedQuestionIndex, 1)!;
      reorderedQuestions.splice(newPosition, 0, movedQuestion);
      selectedAngleQuestionTheme.questions = reorderedQuestions;

      return service.updateAngleQuestionsOrder(questionId, newPosition);
    },
    [service, selectedAngleQuestionThemes]
  );

  const addAngleQuestionTheme = useCallback(() => {
    return handleUnsavedChanges().then(() => {
      const theme = {
        id: -1,
        title: "",
        type: AngleQuestionType.Client,
        questions: [],
        angleTypeId: selectedAngleTypeId!,
      };
      setSelectedAngleQuestionThemes((prev) => [...prev, theme]);
      handleSetIsAddingAngleQuestionTheme(true);
      return theme;
    });
  }, [handleSetIsAddingAngleQuestionTheme, handleUnsavedChanges, selectedAngleTypeId]);

  useEffect(
    function getAngleQuestionsCount() {
      if (currentUser && projectToken) {
        service.getAngleQuestionsCount(projectToken).then((counts) => {
          setAngleQuestionsCount(counts);
          setAngleQuestionsCountLoading(false);
        });
      }
    },
    [currentUser, projectToken, service]
  );

  useEffect(
    function getAngleQuestionThemes() {
      if (!projectToken || !selectedAngleTypeId || !currentUser) return;
      service.findAngleQuestionThemes(projectToken, selectedAngleTypeId).then((themes) => {
        setSelectedAngleQuestionThemes(themes);
        setAngleQuestionThemesLoading(false);
      });
    },
    [currentUser, projectToken, selectedAngleTypeId, service]
  );

  const context = {
    enableAngleQuestions,
    getAngleQuestionThemes,
    updateProjectBrief,
    selectedAngleTypeId,
    setSelectedAngleTypeId,
    angleQuestionsCount,
    angleQuestionsCountLoading,
    selectedAngleQuestionThemes,
    deleteAngleQuestion,
    deleteAngleQuestionTheme,
    copyAngleThemeQuestions,
    angleQuestionThemesLoading,
    addAngleQuestion,
    updateAngleQuestion,
    createOrUpdateAngleQuestionTheme,
    getAngleThemeQuestionsText,
    reorderAngleQuestionThemes,
    reorderAngleQuestions,
    readOnly,
    addingQuestionThemeId,
    setAddingQuestionThemeId: handleAddingQuestionThemeId,
    editingQuestionId,
    setEditingQuestion: handleSetEditingQuestion,
    questionText,
    setQuestionText,
    editingQuestionThemeId,
    setEditingQuestionTheme: handleSetEditingQuestionTheme,
    questionThemeTitle,
    isAddingAngleQuestionTheme,
    addAngleQuestionTheme,
    setQuestionThemeTitle,
  };

  return <AngleQuestionsContext.Provider value={context} children={children} />;
};

export const useAngleQuestionsContext = () => {
  const context = useContext(AngleQuestionsContext);

  if (!context) throw new Error("AngleQuestionsContext should only be used within AngleQuestionsProvider");

  return context;
};
