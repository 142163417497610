import { useCallback } from "react";
import { PrivateLibraryConversation, PrivateLibraryItem, privateLibraryApi } from "@alphasights/portal-api-client";
import { useQuery } from "query-utils";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ENABLE_PAST_PROJECT_DELIVERABLES, useProjectBadgeContext } from "providers/BadgeProvider";

export const usePrivateLibrary = () => {
  const { hasProjectBadge } = useProjectBadgeContext();
  const { project } = useCurrentProjectContext();
  const isEnabled = hasProjectBadge(ENABLE_PAST_PROJECT_DELIVERABLES) && !!project?.token;

  const { data: privateLibraryItems = [], isLoading } = useQuery<PrivateLibraryItem[]>(
    ["privateLibrary", project?.token],
    () => privateLibraryApi.getPrivateLibrary(project!.token),
    {
      enabled: isEnabled,
      refetchOnMount: false,
    }
  );

  const getPrivateLibraryItemFromInteraction = useCallback(
    (interaction: Interaction) => {
      const transcriptRequestIds = (interaction.recordings || []).flatMap((r) =>
        r.transcriptRequests.map((tr) => tr.id)
      );
      return privateLibraryItems.find((item) => transcriptRequestIds.includes(item.transcriptRequestId));
    },
    [privateLibraryItems]
  );

  return {
    privateLibraryItems,
    getPrivateLibraryItemFromInteraction,
    isLoading,
  };
};

export const usePrivateLibraryContent = ({ privateLibraryId }: { privateLibraryId?: string }) => {
  const { hasProjectBadge } = useProjectBadgeContext();
  const { project } = useCurrentProjectContext();
  const isEnabled = hasProjectBadge(ENABLE_PAST_PROJECT_DELIVERABLES) && !!project?.token;

  const { data: privateLibraryConversation, isLoading } = useQuery<PrivateLibraryConversation>(
    ["privateLibraryContent", project?.token, privateLibraryId],
    () => privateLibraryApi.getPrivateLibraryContent(project!.token, privateLibraryId!!),
    {
      enabled: isEnabled && Boolean(privateLibraryId),
    }
  );

  return { privateLibraryConversation, isLoading, isEnabled };
};
