import {
  Select,
  SelectItem,
  Option,
  Pill,
  Typography,
  Icon,
  useThemeTokens,
  Button,
  Popover as AdsPopover,
} from "@alphasights/alphadesign-components";
import { Add } from "@alphasights/alphadesign-icons";
import styled, { x } from "@xstyled/styled-components";
import { useCustomerKnowledgeContext } from "providers/CustomerKnowledgeProvider";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";
import _ from "lodash";
import { Experience } from "models/Interaction";
import { ButtonWithMargin } from "@alphasights/client-portal-shared";
import { FilterChip } from "components/InteractionsPage/FilterChip";
import { useExpertFiltersStyles } from "components/InteractionsPage/ExpertFilters.styles";

export const VendorFilter = () => {
  const { appliedFilters } = useCustomerKnowledgeContext();
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const ref = useRef(null);
  const togglePopover = (event?: React.MouseEvent) => {
    setAnchorEl(!anchorEl ? event?.currentTarget : undefined);
  };
  const closePopover = () => {
    setAnchorEl(undefined);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <FilterChip
        title="Vendor"
        onClick={togglePopover}
        appliedFilters={_.flatten(Object.values(appliedFilters.vendors ?? {}))}
        testId="filter-pill-vendor"
        open={open}
      />
      {open && (
        <Popover
          ref={ref}
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          enterDelay={0}
          portal={false}
          zIndex={30}
        >
          <PopoverContent onSubmit={closePopover} />
        </Popover>
      )}
    </>
  );
};

const PopoverContent = ({ onSubmit }: { onSubmit: () => void }) => {
  const { interactions, applyFilters, appliedFilters, filterInteractions } = useCustomerKnowledgeContext();

  const availableVendors = useMemo(() => {
    return _.sortBy(
      _.uniqBy(
        interactions.flatMap((i) => i.customerKnowledges).map((ck) => ck.vendor),
        "id"
      ),
      "name"
    );
  }, [interactions]);

  const emptyFilter = useMemo(() => availableVendors.reduce((acc, vendor) => ({ ...acc, [vendor.id]: [] }), {}), [
    availableVendors,
  ]);

  const [currentFilters, setCurrentFilters] = useState<{ [key: string]: string[] }>(emptyFilter);

  const onApply = () => {
    applyFilters({ vendors: currentFilters });
    onSubmit();
  };

  const onReset = () => {
    setCurrentFilters(emptyFilter);
    applyFilters({ vendors: emptyFilter });
    onSubmit();
  };

  const allFilters = useMemo(() => {
    return Object.values(Experience)
      .filter((exp) => Object.values(currentFilters).every((selected) => selected.includes(exp)))
      .sort();
  }, [currentFilters]);

  const onChangeCurrentFilters = useCallback(
    (filters: { [key: number]: string[] }) => {
      setCurrentFilters(
        Object.entries({
          ...emptyFilter,
          ...filters,
        }).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: (value as string[]).sort(),
          };
        }, {})
      );
    },
    [emptyFilter]
  );

  useEffect(() => {
    onChangeCurrentFilters(appliedFilters.vendors || {});
  }, [onChangeCurrentFilters, appliedFilters.vendors]);

  const onAllChanged = (items: string[]) => {
    const added = _.difference(items, allFilters);
    const removed = _.difference(allFilters, items);

    const filters = Object.entries(currentFilters).reduce((acc, [key, value]) => {
      return {
        ...acc,
        // @ts-ignore
        [key]: _.uniq([...value, ...added].filter((v) => !removed.includes(v))),
      };
    }, {});

    onChangeCurrentFilters(filters);
  };

  const onVendorChanged = (vendor: Vendor, exps: string[]) => {
    onChangeCurrentFilters({ ...currentFilters, [vendor.id]: exps });
  };

  const projectedCount = useMemo(() => {
    return filterInteractions(interactions, {
      ...appliedFilters,
      vendors: currentFilters,
    }).length;
  }, [currentFilters, appliedFilters, filterInteractions, interactions]);

  const applyButtonLabel = useMemo(() => {
    if (projectedCount === 0) return "No experts";
    if (projectedCount === 1) return "Show 1 expert";

    return `Show ${projectedCount} experts`;
  }, [projectedCount]);

  const { buttonsWrapper } = useExpertFiltersStyles();

  return (
    <x.div data-testid="filter-dropdown-content">
      <x.div
        display="grid"
        gridTemplateColumns="1fr 315px"
        alignItems="center"
        rowGap="4px"
        columnGap="16px"
        px="16px"
        py="12px"
      >
        <VendorSelect vendor={{ id: "all", name: "All", logo: "" }} onChanged={onAllChanged} value={allFilters} />
        {availableVendors.map((vendor) => {
          return (
            <VendorSelect
              key={vendor.id}
              vendor={vendor}
              onChanged={(val: string[]) => onVendorChanged(vendor, val)}
              value={currentFilters[vendor.id]}
            />
          );
        })}
      </x.div>
      <x.div {...buttonsWrapper}>
        <ButtonWithMargin variant="ghost" onClick={onReset} data-testid="clear-filter-button">
          Reset
        </ButtonWithMargin>
        <Button ml="auto" variant="secondary" onClick={onApply} data-testid="save-filter-button">
          {applyButtonLabel}
        </Button>
      </x.div>
    </x.div>
  );
};

const VendorSelect = ({ vendor, onChanged, value }: { vendor: Vendor; onChanged: any; value: any }) => {
  const tokens = useThemeTokens();

  const renderAsPill = (selected: SelectItem | SelectItem[], ix: number = 0): React.ReactNode => {
    if (Array.isArray(selected)) return selected.map(renderAsPill);

    return (
      <Pill
        isInteractive={false}
        onClose={() => {
          const newValue: string[] = value.filter((exp: string) => exp !== selected.value);

          onChanged(newValue);
        }}
        size="small"
        ml={ix > 0 ? tokens.spacing.inner.base : undefined}
      >
        {selected.label}
      </Pill>
    );
  };

  return (
    <>
      <Typography variant="body">{vendor.name}</Typography>
      <Select
        renderSelectedValue={renderAsPill}
        placeholder="Selection state"
        minWidth="200px"
        allowMultiple
        // @ts-ignore
        onChange={(val) => {
          onChanged(val ? val : []);
        }}
        value={value.length === 0 ? undefined : value}
        size="small"
        rightAccessory={
          <Icon size="small">
            <Add />
          </Icon>
        }
        allowRightAccessoryRotation={false}
        py={value.length > 0 ? "4px" : "8px"}
        pl={value.length > 0 ? "4px" : "12px"}
        pr="12px"
        wrapItems={false}
        data-testid={`select-vendor-${vendor.id}`}
      >
        {Object.entries(Experience).map(([label, id]) => {
          return (
            <Option key={id} value={id} label={label} listOptionAccessories={{ inputType: "checkbox", type: "text" }}>
              {label}
            </Option>
          );
        })}
      </Select>
    </>
  );
};

const Popover = styled(AdsPopover)`
  .body-content {
    padding: 0;
  }
  z-index: 100;
`;
