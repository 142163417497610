export enum Badge {
  // TODO [CON-2442] remove newsletter badge once feature is ready to go live
  contentNewsletter = "CONTENT_NEWSLETTER",
  // TODO [CON-3541]: Remove Private Contributor Badge
  privateContributor = "PRIVATE_CONTRIBUTOR",
  newDeliverablesSearch = "NEW_DELIVERABLES_SEARCH",
  privateTranscriptDownload = "PRIVATE_TRANSCRIPT_DOWNLOAD",
  requestSurvey = "REQUEST_SURVEY",
  // TODO [REDIS2-502]: Remove Surveys Results Badge
  surveysResults = "SURVEYS_RESULTS",
  markets = "MARKETS",
  // TODO [RD1-134]: Remove Company Metadata Badge
  companyMetadata = "COMPANY_METADATA",
  //TODO: [RD1-339]: Remove Expert Details Badge,
  expertDetails = "EXPERT_DETAILS",
  thirdPartyDocUpload = "THIRD_PARTY_DOC_UPLOAD",
  //TODO: [RD1-196]: Remove Search History Badge,
  searchHistory = "SEARCH_HISTORY",
}
