import { useEffect, useState, useCallback } from "react";
import useQueryParams from "hooks/useQueryParams";
import { UrlParam } from "views/DeliverablesView/constants";

const useScrollToFragment = (fragmentElements: HTMLDivElement[]) => {
  const [scrolledToFragmentIndex, setScrolledToFragmentIndex] = useState<number | null>(null);
  const [isScrolling, setIsScrolling] = useState(false);

  const queryParams = useQueryParams();

  const removePartFromUrl = useCallback(() => {
    setScrolledToFragmentIndex(null);
    queryParams.delete(UrlParam.part, true);
  }, [queryParams]);

  useEffect(() => {
    if (!fragmentElements.length) {
      return;
    }

    const part = queryParams.get(UrlParam.part);
    if (!part) {
      return;
    }

    // Validate that part is a valid positive integer
    if (!/^[0-9]\d*$/.test(part)) {
      setScrolledToFragmentIndex(null);
      removePartFromUrl();
      return;
    }

    const partInt = parseInt(part);
    if (partInt < 0 || partInt >= fragmentElements.length) {
      removePartFromUrl();
      return;
    }

    setIsScrolling(true);
    fragmentElements[partInt].scrollIntoView({ behavior: "smooth" });

    const scrollTimeout = setTimeout(() => {
      setIsScrolling(false);
      setScrolledToFragmentIndex(partInt);
    }, 1000);

    const resetTimeout = setTimeout(() => {
      setScrolledToFragmentIndex(null);
    }, 2000);

    return () => {
      clearTimeout(scrollTimeout);
      clearTimeout(resetTimeout);
    };
  }, [queryParams, fragmentElements, removePartFromUrl]);

  return { scrolledToFragmentIndex, isScrolling };
};

export default useScrollToFragment;
