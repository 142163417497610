import { fetch } from "../hooks/useApi";
import { AngleQuestion, AngleQuestionCount, AngleQuestionTheme } from "models/AngleQuestions";

export const angleQuestionsService = {
  async findAngleQuestionThemes(projectToken: string, angleTypeId: number): Promise<AngleQuestionTheme[]> {
    return fetch({
      url: `/api/projects/${projectToken}/angle-question-themes/${angleTypeId}`,
      handleForbidden: true,
    }).then((res) => res.json());
  },

  async getAngleQuestionsCount(projectToken: string): Promise<AngleQuestionCount[]> {
    return fetch({
      url: `/api/projects/${projectToken}/angle-question-themes/count-by-angle-type`,
      handleForbidden: true,
    }).then((res) => res.json());
  },

  async updateBrief(projectToken: string, updatedBrief: String): Promise<String> {
    return fetch({
      url: `/api/projects/${projectToken}/brief`,
      method: "PATCH",
      body: updatedBrief,
    }).then((res) => res.text());
  },

  async deleteAngleQuestion(questionId: number): Promise<void> {
    return fetch({
      method: "DELETE",
      url: `/api/angle-questions/question/${questionId}`,
      handleForbidden: true,
    }).then(() => Promise.resolve());
  },

  async deleteAngleQuestionTheme(projectToken: string, themeId: number): Promise<void> {
    return fetch({
      method: "DELETE",
      url: `/api/projects/${projectToken}/angle-question-themes/${themeId}`,
      handleForbidden: true,
    }).then(() => Promise.resolve());
  },

  async addAngleQuestion(angleThemeId: number, text: string): Promise<AngleQuestion> {
    return fetch({
      method: "POST",
      url: `/api/angle-questions/${angleThemeId}/question`,
      body: text,
      handleForbidden: true,
    }).then((res) => res.json());
  },

  async updateAngleQuestion(questionId: number, text: string): Promise<AngleQuestion> {
    return fetch({
      method: "PATCH",
      url: `/api/angle-questions/question/${questionId}`,
      body: text,
      handleForbidden: true,
    }).then((res) => res.json());
  },

  async updateAngleQuestionTheme(projectToken: string, themeId: number, name: string): Promise<AngleQuestionTheme> {
    return fetch({
      method: "PATCH",
      url: `/api/projects/${projectToken}/angle-question-themes/${themeId}`,
      body: name,
      handleForbidden: true,
    }).then((res) => res.json());
  },

  async updateAngleQuestionThemeOrder(projectToken: string, angleThemeId: number, newPosition: number): Promise<void> {
    return fetch({
      method: "PATCH",
      url: `/api/projects/${projectToken}/angle-question-themes/${angleThemeId}/position/${newPosition}`,
      handleForbidden: true,
    }).then(() => Promise.resolve());
  },

  async updateAngleQuestionsOrder(questionId: number, newPosition: number): Promise<void> {
    return fetch({
      method: "PATCH",
      url: `/api/angle-questions/question/${questionId}/position/${newPosition}`,
      handleForbidden: true,
    }).then(() => Promise.resolve());
  },

  async addAngleQuestionTheme(projectToken: string, angleTypeId: number, title: string): Promise<AngleQuestionTheme> {
    return fetch({
      method: "POST",
      url: `/api/projects/${projectToken}/angle-question-themes/${angleTypeId}`,
      body: title,
      handleForbidden: true,
    }).then((res) => res.json());
  },
};
